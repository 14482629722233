
export const emailCase = {
    userCreation:       'userCreation',
    passwordReset:      'passwordReset',
    deleteUser:         'deleteUser',
    updateUser:         'updateUser',
    createDepartment:   'createDepartment',
    updateDepartment:   'updateDepartment',
    deleteDepartment:   'deleteDepartment',
    createEmployee:     'createEmployee',
    updateEmployee:     'updateEmployee',
    deleteEmployee:     'deleteEmployee',
    createLeave:        'createLeave',
    notifyLeave:        'notifyLeave',
    approveLeave:       'approveLeave',
    rejectLeave:        'rejectLeave',
    deleteLeave:        'deleteLeave',
    createPayroll:      'createPayroll',
    updatePayroll:      'updatePayroll',
    deletePayroll:      'deletePayroll',
    userLoggedIn:       'userLoggedIn',
    makeRequisition:    'makeRequisition',
    approveRequisition: 'approveRequisition',
    rejectRequisition:  'rejectRequisition',
    createSupportTicket:'createSupportTicket',
    remindSupportTicket:'remindSupportTicket',
    createSupportTicketAdmin:'createSupportTicketAdmin',
    employeePayrollGenerated: 'employeePayrollGenerated',
    createTask:         'createTask',
    updateTask:         'updateTask',
    deleteTask:         'deleteTask',
    createJob:          'createJob',
    candidateAppliedUser:   'candidateAppliedUser',
    candidateAppliedAdmin: 'candidateAppliedAdmin',
    approveCandidate:   'approveCandidate',
    rejectCandidate:    'rejectCandidate',
    sendAirtime:        'sendAirtime',
    walletAccountCreated:     'walletAccountCreated',
    fundAccount:        'fundAccount',
    attendanceApprovalRequest: 'attendanceApprovalRequest',
    createReport:         'createReport',
    deleteReport:         'deleteReport',
}
