import { getUser } from './common';

export const API_URL = 'https://hrapi-i921.onrender.com/api/v1';
// export const API_URL = 'http://localhost:5050/api/v1';
export const USER_ID = getUser.id;
export const USER_TOKEN = getUser.token;
export const SUPPORT_MAIL = 'support@hrmaneja.com';
export const HOLIDAY_API_KEY = 'cf3d4336adf647378398f766648304d7'
export const FLUTTERWAVE_TOKEN = 'Bearer FLWSECK_TEST-398699f82dcf8f31a47672afeb24a451-X'
export const GOOGLE_ANALYTICS_ID = 'G-CNY3YNHV6X';
export const CLOUDINARY_CLOUD_NAME = 'archer';
export const CLOUDINARY_UPLOAD_PRESET = 'hrmaneja-documents';