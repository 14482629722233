
import React from 'react'
const Loader = () => {
    return (
        <div className="container-fluid col-md-12 align-center">
            <div style={{width: '100%', height: '100%', marginTop: '100px'}} className="loader"></div>
        </div>
    );
}

export default Loader;